import moment from 'moment-timezone';

export const state = () => ({
    cache: {}
});

export const mutations = {
    setPrices(state, search) {
        state.cache[search.departure + '~' + search.destination + '~' + search.search_type] = search.calendar;
    }
};
