import callAxios from "../../plugins/axios/call";

export default remote => ({
    code: (search) => callAxios(remote, 'post', 'flight/search/code', search, 2),
    infoCode: (search) => callAxios(remote, 'get', `flight/search/code/${search}`, null, 1),
    search: (search, params) => callAxios(remote, 'post', `flight/search/${search}`, params, 3),
    alternatives: (search) => callAxios(remote, 'get', `flight/search/alternatives/${search}`, null, 4),
    map: (data) => callAxios(remote, 'post', `flight/map`, data, 5),
    anywhere: (search, params) =>  callAxios(remote, 'post', `flight/search/anywhere/${search}`, params, 6)
});
