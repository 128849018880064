import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b17bea0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _046af8f3 = () => interopDefault(import('../pages/aircompanies.vue' /* webpackChunkName: "pages/aircompanies" */))
const _35293c8b = () => interopDefault(import('../pages/booking-status/index.vue' /* webpackChunkName: "pages/booking-status/index" */))
const _05732d6e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _25157cd5 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _6134b250 = () => interopDefault(import('../pages/booking-flight/new/_search_code/_id_solution.vue' /* webpackChunkName: "pages/booking-flight/new/_search_code/_id_solution" */))
const _4df09a8c = () => interopDefault(import('../pages/booking-complete/_code.vue' /* webpackChunkName: "pages/booking-complete/_code" */))
const _5624fbc0 = () => interopDefault(import('../pages/search-flight/_code.vue' /* webpackChunkName: "pages/search-flight/_code" */))
const _e6d1192e = () => interopDefault(import('../pages/booking-payment/_code/_payment.vue' /* webpackChunkName: "pages/booking-payment/_code/_payment" */))
const _9628559e = () => interopDefault(import('../pages/booking-status/_email/_code.vue' /* webpackChunkName: "pages/booking-status/_email/_code" */))
const _e7ee8d80 = () => interopDefault(import('../pages/booking/_code/_step.vue' /* webpackChunkName: "pages/booking/_code/_step" */))
const _1aa0b295 = () => interopDefault(import('../pages/aircompany.vue' /* webpackChunkName: "pages/aircompany" */))
const _00b41230 = () => interopDefault(import('../pages/flights-to-destination.vue' /* webpackChunkName: "pages/flights-to-destination" */))
const _6e8e92ad = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _7b17bea0,
    name: "index___en"
  }, {
    path: "/it",
    component: _7b17bea0,
    name: "index___it"
  }, {
    path: "/ro",
    component: _7b17bea0,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _7b17bea0,
    name: "index___ru"
  }, {
    path: "/en/airlines",
    component: _046af8f3,
    name: "aircompanies___en"
  }, {
    path: "/en/booking-status",
    component: _35293c8b,
    name: "booking-status___en"
  }, {
    path: "/en/contact",
    component: _05732d6e,
    name: "contact___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _25157cd5,
    name: "terms___en"
  }, {
    path: "/it/compagnie-aeree",
    component: _046af8f3,
    name: "aircompanies___it"
  }, {
    path: "/it/contact",
    component: _05732d6e,
    name: "contact___it"
  }, {
    path: "/it/status-prenotazione",
    component: _35293c8b,
    name: "booking-status___it"
  }, {
    path: "/it/termini-e-condizioni",
    component: _25157cd5,
    name: "terms___it"
  }, {
    path: "/ro/companii-aeriene",
    component: _046af8f3,
    name: "aircompanies___ro"
  }, {
    path: "/ro/contact",
    component: _05732d6e,
    name: "contact___ro"
  }, {
    path: "/ro/status-rezervare",
    component: _35293c8b,
    name: "booking-status___ro"
  }, {
    path: "/ro/termeni-si-conditii",
    component: _25157cd5,
    name: "terms___ro"
  }, {
    path: "/ru/aviakompanii",
    component: _046af8f3,
    name: "aircompanies___ru"
  }, {
    path: "/ru/contact",
    component: _05732d6e,
    name: "contact___ru"
  }, {
    path: "/ru/pravila-i-usloviia",
    component: _25157cd5,
    name: "terms___ru"
  }, {
    path: "/ru/status-bronirovaniya",
    component: _35293c8b,
    name: "booking-status___ru"
  }, {
    path: "/en/booking-flight/new/:search_code?/:id_solution?",
    component: _6134b250,
    name: "booking-flight-new-search_code-id_solution___en"
  }, {
    path: "/it/booking-flight/new/:search_code?/:id_solution?",
    component: _6134b250,
    name: "booking-flight-new-search_code-id_solution___it"
  }, {
    path: "/ro/booking-flight/new/:search_code?/:id_solution?",
    component: _6134b250,
    name: "booking-flight-new-search_code-id_solution___ro"
  }, {
    path: "/ru/booking-flight/new/:search_code?/:id_solution?",
    component: _6134b250,
    name: "booking-flight-new-search_code-id_solution___ru"
  }, {
    path: "/en/booking-complete/:code?",
    component: _4df09a8c,
    name: "booking-complete-code___en"
  }, {
    path: "/en/search-flight/:code",
    component: _5624fbc0,
    name: "search-flight-code___en"
  }, {
    path: "/it/booking-complete/:code?",
    component: _4df09a8c,
    name: "booking-complete-code___it"
  }, {
    path: "/it/ricerca-volo/:code",
    component: _5624fbc0,
    name: "search-flight-code___it"
  }, {
    path: "/ro/booking-complete/:code?",
    component: _4df09a8c,
    name: "booking-complete-code___ro"
  }, {
    path: "/ro/cautare-zbor/:code",
    component: _5624fbc0,
    name: "search-flight-code___ro"
  }, {
    path: "/ru/booking-complete/:code?",
    component: _4df09a8c,
    name: "booking-complete-code___ru"
  }, {
    path: "/ru/poisk-reisa/:code",
    component: _5624fbc0,
    name: "search-flight-code___ru"
  }, {
    path: "/en/booking-payment/:code?/:payment?",
    component: _e6d1192e,
    name: "booking-payment-code-payment___en"
  }, {
    path: "/en/booking-status/:email/:code",
    component: _9628559e,
    name: "booking-status-email-code___en"
  }, {
    path: "/en/booking/:code?/:step?",
    component: _e7ee8d80,
    name: "booking-code-step___en"
  }, {
    path: "/it/booking-payment/:code?/:payment?",
    component: _e6d1192e,
    name: "booking-payment-code-payment___it"
  }, {
    path: "/it/booking/:code?/:step?",
    component: _e7ee8d80,
    name: "booking-code-step___it"
  }, {
    path: "/it/status-prenotazione/:email/:code",
    component: _9628559e,
    name: "booking-status-email-code___it"
  }, {
    path: "/ro/booking-payment/:code?/:payment?",
    component: _e6d1192e,
    name: "booking-payment-code-payment___ro"
  }, {
    path: "/ro/booking/:code?/:step?",
    component: _e7ee8d80,
    name: "booking-code-step___ro"
  }, {
    path: "/ro/status-rezervare/:email/:code",
    component: _9628559e,
    name: "booking-status-email-code___ro"
  }, {
    path: "/ru/booking-payment/:code?/:payment?",
    component: _e6d1192e,
    name: "booking-payment-code-payment___ru"
  }, {
    path: "/ru/booking/:code?/:step?",
    component: _e7ee8d80,
    name: "booking-code-step___ru"
  }, {
    path: "/ru/status-bronirovaniya/:email/:code",
    component: _9628559e,
    name: "booking-status-email-code___ru"
  }, {
    path: "/en/air-company-:aircompany",
    component: _1aa0b295,
    name: "aircompany___en"
  }, {
    path: "/en/flights-to-:destination",
    component: _00b41230,
    name: "flights-to-destination___en"
  }, {
    path: "/it/compagnia-aerea-:aircompany",
    component: _1aa0b295,
    name: "aircompany___it"
  }, {
    path: "/it/voli-per-:destination",
    component: _00b41230,
    name: "flights-to-destination___it"
  }, {
    path: "/ro/compania-aeriana-:aircompany",
    component: _1aa0b295,
    name: "aircompany___ro"
  }, {
    path: "/ro/zboruri-spre-:destination",
    component: _00b41230,
    name: "flights-to-destination___ro"
  }, {
    path: "/ru/aviakompaniya-:aircompany",
    component: _1aa0b295,
    name: "aircompany___ru"
  }, {
    path: "/ru/reysy-v-:destination",
    component: _00b41230,
    name: "flights-to-destination___ru"
  }, {
    path: "/ru/*",
    component: _6e8e92ad,
    name: "all___ru"
  }, {
    path: "/ro/*",
    component: _6e8e92ad,
    name: "all___ro"
  }, {
    path: "/it/*",
    component: _6e8e92ad,
    name: "all___it"
  }, {
    path: "/en/*",
    component: _6e8e92ad,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
