import callAxios from "../../plugins/axios/call";

export default remote => ({
    get: (booking_code) => callAxios(remote, 'get', `booking/${booking_code}`, null, 11),
    save: (booking_code, data) => callAxios(remote, 'put', `booking/${booking_code}`, data, 12),
    addPeople: (booking_code) => remote.post(`booking/people/${booking_code}`),
    deletePeople: (booking_code, id_people) => remote.delete(`booking/people/${booking_code}/${id_people}`),
    paymentIntention: (booking_code, payment_method, data) => remote.post(
        `booking/payment/intention/${booking_code}/${payment_method}`, data),
    getPayment: (booking_code, payment_id) => remote.get(`booking/payment/${booking_code}/${payment_id}`),
    updatePayment: (booking_code, payment_id, data) => remote.post(
        `booking/payment/update-intention/${booking_code}/${payment_id}`, {data}),
    readMetadata: (booking_code, type) => remote.post(`booking/read-metadata/${booking_code}/${type}`),
    checkInInfo: (booking_code) => remote.get(`booking/check-in/${booking_code}`),
    saveCheckInInfo: (booking_code, people) => remote.post(`booking/check-in/${booking_code}`, {people: people}),
    verifyId: (internal_code) => remote.post(`booking/verify-internal-code/${internal_code}`),
    verifyIdAndEMail: (internal_code, email) => remote.post(`booking/verify-internal-code-email/${internal_code}/${email}`),
    getIdAndEMail: (internal_code, email) => remote.post(`booking/get-code-email/${internal_code}/${email}`)
});
