import Vue from 'vue';

export default (d, inject) => {
    let helpers = {
        customCurrency: () => {
            let currency = d.$cookies.get('_c');
            switch (currency) {
                case 1:
                    return 'EUR';
                case 2:
                    return 'USD';
                case 3:
                    return 'GBP';
                case 4:
                    return 'RON';
                case 5:
                    return 'MDL';
                case 6:
                    return 'RUB';
            }
            return d.i18n.localeProperties.currency;
        },
        setCustomCurrency: (currency) => {
            let currencyCode;
            switch (currency) {
                case 'EUR':
                    currencyCode = 1;
                    break;
                case 'USD':
                    currencyCode = 2;
                    break;
                case 'GBP':
                    currencyCode = 3;
                    break;
                case 'RON':
                    currencyCode = 4;
                    break;
                case 'MDL':
                    currencyCode = 5;
                    break;
                case 'RUB':
                    currencyCode = 6;
                    break;
            }
            d.$cookies.set('_c', currencyCode, {
                'path': '/',
                'maxAge': 31536000
            });
        },
        durationHuman: (minutes) => {
            if (minutes < 60 && Math.floor(minutes / 60) == 0) {
                return ['duration.Just m', {min: minutes % 60}];
            } else if (minutes % 60 == 0 && minutes / 60 > 0) {
                return ['duration.Just h', {hour: minutes / 60}];
            } else if (minutes % 60 != 0 && minutes / 60 > 0) {
                return ['duration.h and m', {hour: Math.floor(minutes / 60), min: minutes % 60}];
            }
        },
        shuffleSeeder: (array, seed) => {                // <-- ADDED ARGUMENT
            var m = array.length, t, i;
            let randomSeeder = (seed) => {
                var x = Math.sin(seed++) * 10000;
                return x - Math.floor(x);
            };
            // While there remain elements to shuffle…
            while (m) {

                // Pick a remaining element…
                i = Math.floor(randomSeeder(seed) * m--);        // <-- MODIFIED LINE

                // And swap it with the current element.
                t = array[m];
                array[m] = array[i];
                array[i] = t;
                ++seed                                     // <-- ADDED LINE
            }

            return array;
        },
        stringToNumberSeed: (string) => {
            return ("" + string).split('')
                .map(char => char.charCodeAt(0))
                .reduce((current, previous) => previous + current)
        },
        randomSeeder: (seed) => {
            var x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        },
        distance: (distance, format) => {
            if (format === 'km') {
                return Math.floor(distance) + ' km.';
            } else {
                return Math.floor(distance * 0.621371) + ' miles';
            }
        },
        baseDomain: () => {
            if (process.client) {
                return window.location.protocol + '//' + window.location.host;
            } else {
                return 'https://' + d.req.headers.host;
            }
        },
        numberFormat: (number, decimals, dec_point, thousands_point) => {
            if (number == null || !isFinite(number)) {
                throw new TypeError('number is not valid');
            }

            if (typeof decimals === 'undefined') {
                var len = number.toString()
                    .split('.').length;
                decimals = len > 1 ? len : 0;
            }

            if (typeof dec_point === 'undefined') {
                dec_point = '.';
            }

            if (typeof thousands_point === 'undefined') {
                thousands_point = ',';
            }

            number = parseFloat(number)
                .toFixed(decimals);

            number = number.replace('.', dec_point);

            var splitNum = number.split(dec_point);
            splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
            number = splitNum.join(dec_point);

            return number;
        },
        amountStandard: (amount, currency, short, convert, rateFix) => {
            let settings;

            let currencyInfo = d.store.getters['currencies/getCurrencyById'](currency);

            if (rateFix) {
                currencyInfo.value = rateFix;
            }

            console.log('currencyInfo', currencyInfo);

            if (short) {
                settings = currencyInfo.format_short;
            } else {
                settings = currencyInfo.format;
            }

            if (typeof convert === 'undefined') {
                convert = true;
            }

            if (convert) {
                amount = amount * currencyInfo.value;
            }

            var formatted = '';
            if (settings.symbolPosition === 'front') {
                formatted += settings.symbol;
                if (settings.symbolSpacing) {
                    formatted += ' ';
                }
            }

            formatted += helpers.numberFormat(amount, settings.fractionCount, settings.fractionSeparator,
                settings.thousandsSeparator);

            if (settings.symbolPosition === 'after') {
                if (settings.symbolSpacing) {
                    formatted += ' ';
                }
                formatted += settings.symbol;
            }
            return formatted;
        }
    };
    inject('helpers', helpers);
}

// Vue.prototype.$helpers = {
//     durationHuman,
//     shuffleSeeder,
//     randomSeeder,
//     distance,
//     baseDomain
// };
