import axios from "@/plugins/axios";
import {API} from "@/api/api";

export default function (context) {
    // Here we return a Promise in the middleware to make it asynchronous
    // Cf doc https://nuxtjs.org/guide/routing#middleware
    // So we return a promise that can only be resolved when the function resolve is called
    return new Promise(resolve => API.userDataSession.load(context.$cookies.get('session'), {session_login: context.$cookies.get('login_session')}).then((res) => {
            context.$cookies.set('session', res.data.data_session.key, {
                path: '/',
                maxAge: 63072000
            });

            if (typeof res.data.web_user !== 'undefined') {
                console.log(res.data);
                context.store.commit('user/setLogged', true);
                context.store.commit('user/setEmail', res.data.web_user.email);
                context.store.commit('user/setPhone', res.data.web_user.phone);
                context.store.commit('user/setFirstName', res.data.web_user.first_name);
                context.store.commit('user/setLastName', res.data.web_user.last_name);
                context.store.commit('user/setAvatar', res.data.web_user.avatar);
                context.store.commit('user/setExpiredAt', res.data.session.expired_at);
            }

            resolve();
        })
            .catch((err) => {
                resolve();
            })
    );
}