import moment from 'moment-timezone';

export const state = () => ({
    search_type   : 'roundtrip',
    departure     : '',
    destination   : '',
    departure_date: moment().add(7, 'days').format('YYYY-MM-DD'),
    return_date   : moment().add(14, 'days').format('YYYY-MM-DD'),
    adults        : 1,
    children      : 0,
    infants       : 0,
    travelclass   : 'economy'
});

export const mutations = {
    setSearch (state, search) {
        console.log('set date 1', search.departure_date);
        state.search_type    = search.search_type;
        state.departure      = search.departure;
        state.destination    = search.destination;
        state.departure_date = search.departure_date;
        state.return_date    = search.return_date;
        state.adults         = 1 * (search.adults);
        state.children       = 1 * (search.children);
        state.infants        = 1 * (search.infants);
        state.travelclass    = search.travelclass;
        state.search_code    = search.search_code;
    },
    setTravelclass (state, value) {
        state.travelclass = value;
    },
    setSearchType (state, value) {
        state.search_type = value;
    },
    setAdults (state, value) {
        state.adults = 1 * (value);
    },
    setChildren (state, value) {
        state.children = 1 * (value);
    },
    setInfants (state, value) {
        state.infants = 1 * (value);
    },
    setDepartureDate (state, value) {
        console.log('set date 2', value);
        state.departure_date = value;
    },
    setReturnDate (state, value) {
        state.return_date = value;
    },
    setDeparture (state, value) {
        state.departure = value;
    },
    setDestination (state, value) {
        state.destination = value;
    }
};
