import Vue from 'vue';

const maxMobileSize = 768;
const maxTabletSize = 1024;

Vue.prototype.$responsive = {
    isMobile: () => {
        if (process.client) {
            return window.innerWidth < maxMobileSize;
        }
        return false;
    },
    isTablet: () => {
        if (process.client) {
            return window.innerWidth < maxTabletSize && window.innerWidth > maxMobileSize;
        }
        return false;
    }
};
