
export default {
  methods: {
    async initSession() {
    }
  },
  async fetch() {
    await this.initSession();
  },
}
