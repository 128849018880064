// import original from './original.json';
import real from './real.json';
import decryptHandbook from './decrypt-handbook';

const codes = [702.8662420382166, 702.5477707006369, 702.2292993630573, 701.9108280254777, 701.592356687898, 701.2738853503184, 700.9554140127389, 700.6369426751592, 700.3184713375796, 700, 699.6815286624203, 699.3630573248407, 699.0445859872611, 698.7261146496814, 698.4076433121019, 698.0891719745223, 697.7707006369426, 697.452229299363, 697.1337579617834, 696.8152866242038, 696.4968152866242, 696.1783439490446, 695.859872611465, 695.5414012738853, 695.2229299363057, 694.9044585987261, 694.5859872611464, 694.2675159235669, 693.9490445859873, 693.6305732484076, 693.312101910828, 692.9936305732484, 692.6751592356687, 692.3566878980891, 692.0382165605096, 691.7197452229299, 691.4012738853503, 691.0828025477707, 690.764331210191, 690.4458598726114, 690.1273885350319, 689.8089171974522, 689.4904458598726, 689.171974522293, 688.8535031847133, 688.5350318471337, 688.2165605095541, 687.8980891719744, 687.5796178343949, 687.2611464968153, 686.9426751592356, 686.624203821656, 686.3057324840764, 685.9872611464967, 685.6687898089172, 685.3503184713376, 685.0318471337579, 684.7133757961783, 684.3949044585987, 684.0764331210191, 683.7579617834394, 683.4394904458599, 683.1210191082803, 682.8025477707006, 682.484076433121, 682.1656050955414];

const original = codes.map((i) => {
    return String.fromCodePoint(i * decryptHandbook(i));
});

export default (response, salt) => {
    var mapKeys = original;
    var mapValues = real;

    var d = response;

    var string = '';

    var c = 0;

    for (const i of d) {
        string += mapValues[(mapKeys.indexOf(i) - c - salt + (mapValues.length * 10000000)) % mapValues.length];
        c++;
    }

    d = atob(string);

    d = JSON.parse(d);

    return d;
};
