export const state = () => ({
    logged: false,
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    avatar: '',
    expired_at: ''
});

export const mutations = {
    setLogged(state, logged) {
        state.logged = logged;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setPhone(state, phone) {
        state.phone = phone;
    },
    setFirstName(state, first_name) {
        state.first_name = first_name;
    },
    setLastName(state, last_name) {
        state.last_name = last_name;
    },
    setAvatar(state, avatar) {
        state.avatar = avatar;
    },
    setExpiredAt(state, expired_at) {
        state.expired_at = expired_at;
    }
};
