import moment from 'moment-timezone';

export const state = () => ({
    currency: {},
    currencies: []
});

export const mutations = {
    setCurrencies(state, currencies) {
        state.currencies = currencies;
    }
};

export const getters = {
    getCurrencyById: (state) => (code) => {

        console.log('currencies', code, state.currencies, state.currencies.find(currency => currency.code === code));

        return state.currencies.find(currency => currency.code === code);
    }
};
