import { extend, localize }                                               from 'vee-validate';
import Vue                                                                from 'vue';
import { ValidationProvider, ValidationObserver, setInteractionMode }     from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
    console.log('rules', rule);
    extend(rule, rules[rule]);
});

setInteractionMode('lazy');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
