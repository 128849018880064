/*!
 * appearance-changed-event.js - v1.0.0
 * Cross-browser script that fires an `appearance-changed` event when system theme changes (also sets window.appearance)
 * https://github.com/orca-scan/appearance-changed-event
 */
import Vue from "vue";
import moment from "moment-timezone";

! function(e, t) {
    "use strict";
    if ("function" == typeof e.matchMedia && "addEventListener" in e.matchMedia("screen")) {
        var a = e.matchMedia("(prefers-color-scheme: dark)"),
            n = e.matchMedia("(prefers-color-scheme: light)");
        a.addEventListener("change", function(a) {
            a.matches && (e.appearance = "dark", c(t, "appearance-changed", "dark", !0))
        }), n.addEventListener("change", function(a) {
            a.matches && (e.appearance = "light", c(t, "appearance-changed", "light", !0))
        }), a.matches ? e.appearance = "dark" : n.matches && (e.appearance = "light")
    } else console.warn("System appearance detection not supported in this browser");

    function c(a, n, c, r, i) {
        if ("function" != typeof e.CustomEvent && (e.CustomEvent = function(e, a) {
            a = a || {
                bubbles: !1,
                cancelable: !1,
                detail: void 0
            };
            var n = t.createEvent("CustomEvent");
            return n.initCustomEvent(e, a.bubbles, a.cancelable, a.detail), n
        }, e.CustomEvent.prototype = e.Event.prototype), a) {
            var o = {};
            return c && (o.detail = c), o.bubbles = !0 === r, o.cancelable = !0 === i, a.dispatchEvent(new CustomEvent(n, o))
        }
        return !0
    }
}(window, document);

Vue.prototype.$appearance = window.appearance;