import Vue               from 'vue';

let currencies = {
    'USD': {
        name              : 'USD',
        symbol            : '$',
        thousandsSeparator: ',',
        fractionCount     : 2,
        fractionSeparator : '.',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '##',
        short             : {
            symbol            : '$',
            thousandsSeparator: ',',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : false,
            avoidEmptyDecimals: '##'
        }
    },
    'GBP': {
        name              : 'GBP',
        symbol            : '£',
        thousandsSeparator: ',',
        fractionCount     : 2,
        fractionSeparator : '.',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '##',
        short             : {
            symbol            : '£',
            thousandsSeparator: ',',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : false,
            avoidEmptyDecimals: '##'
        }
    },
    'EUR': {
        name              : 'EUR',
        symbol            : '€',
        thousandsSeparator: '.',
        fractionCount     : 2,
        fractionSeparator : ',',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '',
        short             : {
"symbol": "€",
"thousandsSeparator": ".",
"fractionCount"     : 0,
"fractionSeparator" : "",
"symbolPosition"    : "front",
"symbolSpacing"     : false,
"avoidEmptyDecimals": ""
        }
    },
    'RON': {
        name              : 'RON',
        symbol            : 'Lei',
        thousandsSeparator: ' ',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'front',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : 'RON',
            symbol            : 'Lei ',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    },
    'RUB': {
        name              : 'RUB',
        symbol            : '₽',
        thousandsSeparator: ' ',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'front',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : 'RUB',
            symbol            : '₽',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    },
    'MDL': {
        name              : 'MDL',
        symbol            : 'MDL',
        thousandsSeparator: '',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'after',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : '',
            symbol            : 'MDL',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'after',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    }
};
