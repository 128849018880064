export const state = () => ({
    title      : '',
    description: '',
    keywords   : '',
    image      : '',
    canonical  : ''
});

export const mutations = {
    setTitle (state, title) {
        state.title = title + ' | AnyFlight.com';
    },
    setDescription (state, description) {
        state.description = description;
    },
    setKeywords (state, keywords) {
        state.keywords = keywords;
    },
    setImage (state, image) {
        state.image = image;
    },
    setCanonical (state, canonical) {
        state.canonical = canonical;
    }
};
