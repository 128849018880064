export default remote => ({
    all: (website) => remote.get(`content/job/${website}`),
    get: (website, slug) => remote.get(`content/job/${website}/${slug}`),
    uploadCv: (website, file) => {
        let formData = new FormData();
        formData.append("uploadFile", file);

        return remote.post(`content/job/cv`, formData,
            {headers: {'Content-Type': 'multipart/form-data'}});
    },
});
