export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","language":"English","market":"gb","file":"en.js","currency":"EUR","formatDate":"DD-MM-YYYY","formatHour":"h:mmA","resultDate":"ddd Do MMM","searchDate":"MMM Do, YYYY","searchDate2":"dddd","startWeek":-1,"name":"United Kingdom","defaultMapPosition":[51.509865,-0.118092],"distance":"miles","flight":{"search":"no-commission","search_commission_message":false,"booking":"commission","search_price":"total"}},{"code":"it","language":"Italiano","market":"it","file":"it.js","currency":"EUR","formatDate":"DD/MM/YYYY","formatHour":"H:mm","resultDate":"dddd D MMMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"Italia","defaultMapPosition":[41.890209,12.492231],"distance":"km","currencies":["EUR"],"flight":{"search":"no-commission","search_commission_message":false,"booking":"commission","search_price":"total"}},{"code":"ro","language":"Română","market":"ro","file":"ro.js","currency":"EUR","formatDate":"DD.MM.YYYY","formatHour":"H:mm","resultDate":"ddd Do MMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"România","defaultMapPosition":[44.439663,26.096306],"distance":"km","currencies":["EUR","RON"],"flight":{"search":"no-commission","search_commission_message":true,"booking":"commission","search_price":"total"}},{"code":"ru","language":"Русский","market":"md","file":"ru.js","domain":"ru.ninjatrip.md","currency":"EUR","formatDate":"DD.MM.YYYY","formatHour":"H:mm","resultDate":"ddd Do MMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"Молдова","defaultMapPosition":[46.9998477,28.7881374],"distance":"km","currencies":["EUR","MDL"],"flight":{"search":"no-commission","booking":"no-commission","search_price":"total"}}],
  defaultLocale: "",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: {"skipNuxtState":true},
  langDir: "/code/current/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","language":"English","market":"gb","file":"en.js","currency":"EUR","formatDate":"DD-MM-YYYY","formatHour":"h:mmA","resultDate":"ddd Do MMM","searchDate":"MMM Do, YYYY","searchDate2":"dddd","startWeek":-1,"name":"United Kingdom","defaultMapPosition":[51.509865,-0.118092],"distance":"miles","flight":{"search":"no-commission","search_commission_message":false,"booking":"commission","search_price":"total"}},{"code":"it","language":"Italiano","market":"it","file":"it.js","currency":"EUR","formatDate":"DD/MM/YYYY","formatHour":"H:mm","resultDate":"dddd D MMMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"Italia","defaultMapPosition":[41.890209,12.492231],"distance":"km","currencies":["EUR"],"flight":{"search":"no-commission","search_commission_message":false,"booking":"commission","search_price":"total"}},{"code":"ro","language":"Română","market":"ro","file":"ro.js","currency":"EUR","formatDate":"DD.MM.YYYY","formatHour":"H:mm","resultDate":"ddd Do MMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"România","defaultMapPosition":[44.439663,26.096306],"distance":"km","currencies":["EUR","RON"],"flight":{"search":"no-commission","search_commission_message":true,"booking":"commission","search_price":"total"}},{"code":"ru","language":"Русский","market":"md","file":"ru.js","domain":"ru.ninjatrip.md","currency":"EUR","formatDate":"DD.MM.YYYY","formatHour":"H:mm","resultDate":"ddd Do MMM","searchDate":"D MMMM YYYY","searchDate2":"dddd","startWeek":0,"name":"Молдова","defaultMapPosition":[46.9998477,28.7881374],"distance":"km","currencies":["EUR","MDL"],"flight":{"search":"no-commission","booking":"no-commission","search_price":"total"}}],
  localeCodes: ["en","it","ro","ru"],
}

export const localeMessages = {
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'it.js': () => import('../../lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'ro.js': () => import('../../lang/ro.js' /* webpackChunkName: "lang-ro.js" */),
  'ru.js': () => import('../../lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
}
