export default (d, inject) => {
    inject('notifications', {
        subscribe: () => {
            window.$OneSignal.showNativePrompt()
                .then(async (value) => {
                    console.log("Notification", value, await window.$OneSignal.getUserId());
                });
        },
        init() {

        }
    })
};