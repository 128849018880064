import argument from "./decrypt";

export default (m, g, d, e, s) => {
    return new Promise(function (resolve, reject) {
        return m[g](d, e)
            .then((res) => {
                resolve({data: argument(res.data, s)});
            })
            .catch((error) => {
                reject(error);
            });
    });
}