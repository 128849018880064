import axios from '../plugins/axios';
import axiosV4 from '../plugins/axiosV4';
import axiosInternal from '../plugins/axiosInternal';
import axiosContent from '../plugins/axiosContent';
import Vue from 'vue';

import claims from './endpoints/claims';
import contents from './endpoints/contents';
import flightAirports from './endpoints/flightAirports';
import flightAlert from './endpoints/flightAlert';
import flightAircompany from './endpoints/flightAircompany';
import flightCalendar from './endpoints/flightCalendar';
import flightSearch from './endpoints/flightSearch';
import flightIncidents from './endpoints/flightIncidents';
import paymentsHelper from './endpoints/paymentsHelper';
import countries from './endpoints/countries';
import user from './endpoints/user';
import userPassengers from './endpoints/userPassengers';
import userCountry from './endpoints/userCountry';
import userDataSession from './endpoints/userDataSession';
import supportTicket from './endpoints/supportTicket';
import flightBooking from './endpoints/flightBooking';
import booking from './endpoints/booking';
import internal from './endpoints/internal';
import shortLink from './endpoints/shortLink';
import job from './endpoints/job';
import supportContent from "./endpoints/supportContent";
import currencies from "./endpoints/currencies";

export const API = {
    claims: claims(axios),
    contents: contents(axios),
    flightAirports: flightAirports(axios),
    flightAircompany: flightAircompany(axios),
    flightCalendar: flightCalendar(axios),
    flightSearch: flightSearch(axios),
    flightAlert: flightAlert(axios),
    flightIncidents: flightIncidents(axios),
    paymentsHelper: paymentsHelper(axios),
    countries: countries(axios),
    user: user(axios),
    userPassengers: userPassengers(axios),
    userCountry: userCountry(axios),
    userDataSession: userDataSession(axios),
    supportTicket: supportTicket(axios),
    supportContent: supportContent(axios),
    flightBooking: flightBooking(axiosV4),
    booking: booking(axios),
    shortLink: shortLink(axios),
    job: job(axios),
    internal: internal(axiosInternal),
    currencies: currencies(axiosV4),
};
