export default remote => ({
    social: (social) => remote.post(`user/login/social/ninjatrip/${social}`),
    socialComplete: (social, data) => remote.post(`user/login/social-complete/ninjatrip/${social}`, data),
    loggedIn: (session) => remote.post(`user/login/logged-in/${encodeURIComponent(session)}`),
    logout: (session) => remote.post(`user/login/logout/${encodeURIComponent(session)}`),

    login: (email, password) => remote.post(`user/login/log-in/ninjatrip`, {email, password}),
    verifyUser: (email) => remote.post(`user/login/email/ninjatrip`, {email: email}),
    verifyPassword: (session, password) => remote.post(`user/login/verify-password/${encodeURIComponent(session)}`, {password}),
    resetPassword: (email) => remote.post(`user/login/reset-password/ninjatrip`, {email: email}),
    resetPasswordInformation: (code, code2) => remote.post(`user/login/reset-password-information/ninjatrip`, {code, code2}),
    newPasswordCode: (code, code2, password) => remote.post(`user/login/new-password-code/ninjatrip`, {code, code2, password}),

    edit: (session, data) => remote.post(`user/login/edit/${encodeURIComponent(session)}`, data),
    editPassword: (session, data) => remote.post(`user/login/edit-password/${encodeURIComponent(session)}`, data),

    upcomingBookings: (session) => remote.post(`user/booking/upcoming`, null, {params: {session}}),
    bookings: (session, data) => remote.post(`user/booking/list`, data, {params: {session}}),
    relatedBookings: (session) => remote.post(`user/booking/related`, null, {params: {session}}),
    checkInInstructions: (session, internal_code) => remote.post(`user/booking/check-in-instructions/${encodeURIComponent(internal_code)}`, null, {params: {session}})
});
